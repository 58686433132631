
import { Component, Vue, Prop } from "vue-property-decorator";
import ApexCharts from "apexcharts";
import { TransfersUsed } from "@/models/entities/transfers-used.interface";
import { overviewService } from "@/services/api/overview.service";

@Component
export default class StatChart extends Vue {
  @Prop({ required: false, type: String, default: "200px" })
  private height: string;

  private transfersUsed: TransfersUsed = {
    beneficiariesCount: 0,
    monthlyBeneficiaries: 0,
  };

  private chart: ApexCharts = null;
  private loading = false;
  private transfersUsedBar = 0;

  async created(): Promise<void> {
    this.transfersUsed = (await overviewService.GetNumTransfersByMonth()).data;
    this.getData();
  }

  public getData(reload = true): void {
    if (reload) {
      this.loading = false;
    }
    this.buildChart();
  }

  updated(): void {
    if (this.$refs.chartDiv != undefined) {
      if (this.chart == null) {
        this.buildChart();
      }
    }
  }

  private buildChart(): void {
    let beneficiaryRemain =
      this.transfersUsed.monthlyBeneficiaries -
      this.transfersUsed.beneficiariesCount;
    this.$nextTick(() => {
      if (this.$refs.chartDiv == undefined) {
        //aggiorno perchè il canvas non è ancora stato creato
        this.$forceUpdate();
      } else {
        const series = [
          this.transfersUsed.beneficiariesCount,
          beneficiaryRemain,
        ];
        const options = {
          chart: {
            type: "pie",
            height: this.height,
            lenght: "170px",
          },
          colors: ["#EC5E78", "#4954BA"],
          series: series,
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          plotOptions: {
            pie: {
              expandOnClick: false,
            },
          },
          labels: ["Beneficiari", "Beneficiari residui"],
        };
        if (this.chart != null) {
          this.chart.destroy();
        }
        this.chart = new ApexCharts(
          this.$refs.chartDiv as HTMLElement,
          options
        );
        this.chart.render();
      }
    });
  }
}
